export default class Commons {
    static initCommons(container) {
        $('.user-checker', container).each(function(e) {
            let checked = $(this);

            $.ajax({
                url: '/innerapi/check',
                method: 'GET',
                dataType: 'JSON',
                success: function(data) {
                    checked.after('<input type="hidden" name="temp_token" value="' + data['token'] + '">');
                    checked.closest('form').find('button[type=submit]').prop('disabled', false);
                    checked.remove();
                }
            });
        });
    }
}
